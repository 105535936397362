import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import theme from '../../config/theme'
import RecentReading from '../components/recent-reading/recent-reading'

export default function Index() {
  return (
    <Layout headerColor={theme.colors.white} logo={false}>
      <SEO />
      <RecentReading hideAll />
    </Layout>
  )
}
